import fetch from "../utils/fetch";

// 全局配置
export function configList(data) {
  return fetch({
    url: '/api/user/config',
    method: 'post',
    data: data
  });
}

// CustomRules
export function customRules(data) {
    return fetch({
        url: '/api/user/custom-rules',
        method: 'post',
        data: data
    });
}

// 用户信息
export function userInfo(data) {
  return fetch({
    url: '/api/common/info',
    method: 'post',
    data: data
  });
}
// 更新用户信息
export function updateUser(data) {
  return fetch({
    url: '/api/common/change-info',
    method: 'post',
    data: data
  });
}
// 货币列表
export function getCurrentList(data) {
  return fetch({
    url: '/api/user/monetary-function-list',
    method: 'post',
    data
  });
}
// 轮播图
export function getBannerList(data) {
  return fetch({
    url: '/api/user/banner',
    method: 'post',
    data
  });
}
// 购买历史
export function orderList(data) {
  return fetch({
    url: '/api/user/order-list',
    method: 'post',
    data
  });
}

// OrderCancel
export function orderCancel(data) {
  return fetch({
    url: '/api/user/order-cancel',
    method: 'post',
    data
  });
}
// 下单
export function addOrder(data) {
  return fetch({
    url: '/api/user/order',
    method: 'post',
    data
  });
}
// 工单列表
export function workOrderList(data) {
  return fetch({
    url: '/api/user/work-order-list',
    method: 'post',
    data
  });
}
// 工单创建
export function addWorkOrder(data) {
  return fetch({
    url: '/api/user/create-work-order',
    method: 'post',
    data
  });
}
// 工单回复
export function workOrderReply(data) {
  return fetch({
    url: '/api/user/work-order-reply',
    method: 'post',
    data
  });
}
// 工单状态变更
export function changeWorkOrderStatus(data) {
  return fetch({
    url: '/api/user/work-order-status',
    method: 'post',
    data
  });
}
// 检查是否设置交易密码
export function checkPayPassword(data) {
  return fetch({
    url: '/api/common/check-pay-password',
    method: 'post',
    data
  });
}
// 设置交易密码
export function setPayPassword(data) {
  return fetch({
    url: '/api/common/set-pay-password',
    method: 'post',
    data
  });
}
// 修改交易密码
export function editPayPassword(data) {
  return fetch({
    url: '/api/common/change-safe-password',
    method: 'post',
    data
  });
}
// 收支明细列表
export function debtList(data) {
  return fetch({
    url: '/api/common/debt-list',
    method: 'post',
    data
  });
}
// 收支明细类型
export function debtTypeList(data) {
  return fetch({
    url: '/api/common/debt-type',
    method: 'post',
    data
  });
}
// 提现记录
export function withdrwaLog(data) {
  return fetch({
    url: '/api/common/withdraw-list',
    method: 'post',
    data
  });
}
// 提现申请
export function doWithdraw(data) {
  return fetch({
    url: '/api/common/withdraw',
    method: 'post',
    data
  });
}
// 充值记录
export function rechargeLog(data) {
  return fetch({
    url: '/api/common/recharge-list',
    method: 'post',
    data
  });
}
// 充值申请
export function doRecharge(data) {
  return fetch({
    url: '/api/common/recharge',
    method: 'post',
    data
  });
}
// 上传
export function fileUpload(data) {
  return fetch({
    url: '/api/common/upload',
    method: 'post',
    data
  });
}
// 任务统计
export function taskCommission(data) {
  return fetch({
    url: '/api/user/task-commission',
    method: 'post',
    data
  });
}
// 任务创建
export function taskCreate(data) {
  return fetch({
    url: '/api/user/task-order-create',
    method: 'post',
    data
  });
}
// 任务支付
export function taskPay(data) {
  return fetch({
    url: '/api/user/task-order-pay',
    method: 'post',
    data
  });
}
// 任务列表
export function taskList(data) {
  return fetch({
    url: '/api/user/task-order-list',
    method: 'post',
    data
  });
}
